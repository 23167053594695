<template>
<div>
    <v-container style="padding: 0px;">
        <v-row>
            <v-col cols="12" style="padding: 0px;">
                <Surveys v-if="algorithmInfo!=null && ui.busy==0" :algorithmInfo="algorithmInfo" v-model="api_data"/>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import Surveys from '@/components/Surveys/Surveys.vue'

export default {
    components: {
        Surveys
    },
    mounted: function(){
        if((process.env.NODE_ENV=='development' || (this.$route.query.debug=='very_true' && this.$route.query.oo=='7')) && this.$route.query.env){
            this.$store.commit('env',this.$route.query.env)
        }        
        this.getAlgorithmInfo()
    },
    data: function(){
        return {
            app: null,
            ui: {
                busy: 0,
                inputs: {
                    algorithm: 0,
                    classification: []
                },
                uploadAPI_DATA: false,
                questionMap: false,
                form: {
                    raw_api_data: null
                }

            },
            algorithmInfo: null,
            api_data: {
                factors: null,
                values: null
            },
            diagnosis: null
        }
    },
    methods: {
        language: function(){
            return this.$store.getters.language
        },
        busy: function(state){
            if(state){
                this.ui.busy++
            }else{
                this.ui.busy--
            }
        },
        getAlgorithmInfo: function(){
            let self = this
            self.busy(true)

            self.sendRequest('get',self.api+'/getAlgorithmInfo/cua_kidney_cancer').then(function(response){
                self.algorithmInfo = response.data
                self.$set(self.api_data, 'factors', {})
                self.$set(self.api_data, 'values', {})

                for(let factorKey in self.algorithmInfo.factorMap){
                    self.$set(self.api_data.factors, factorKey, [])
                }

                for(let valueKey in self.algorithmInfo.valueMap){
                    let model = self.algorithmInfo.valueMap[valueKey].model
                    self.$set(self.api_data.values, valueKey, model.type=='array' ? [] : model.type=='object' ? {} : null)
                }
                self.busy(false)
            })
        },
        diagnose: function(){
            let self = this;
            self.busy(true)
            self.sendRequest('post',self.api+'/diagnose',this.api_data).then(function(response){
                self.busy(false)
                self.diagnosis = response.data
            })
        },
        generateData: function(algorithmName, classificationName, conditionName){
            let self = this;
            self.busy(true)
            if(algorithmName && classificationName && conditionName){
                self.sendRequest('post',self.api+'/generateData',{
                    target: {
                        algorithm: algorithmName,
                        classification: classificationName,
                        condition: conditionName
                    }
                }).then(function(response){
                    self.busy(false)
                    self.loadProfile(response.data)
                })                
            }else{
                self.sendRequest('post',self.api+'/generateData',{}).then(function(response){
                    self.busy(false)
                    self.loadProfile(response.data)
                })

            }

        },
        loadProfile: function(data){
            let factors = data.factors
            for(let key in factors){
                this.api_data.factors[key] = factors[key]
            }
            let values = data.values
            for(let key in values){
                this.api_data.values[key] = values[key]
            }
        },
        resetData: function(algorithmName, classificationName, conditionName){
            
            let targetFactors = (algorithmName && classificationName && conditionName) ? this.algorithmInfo.algorithm[algorithmName][classificationName][conditionName].diagnosisFactor : this.algorithmInfo.factorMap
            let targetValues = (algorithmName && classificationName && conditionName) ? this.algorithmInfo.algorithm[algorithmName][classificationName][conditionName].diagnosisValue : this.algorithmInfo.valueMap
            
            for(let name in targetFactors){
                this.api_data.factors[name] = []
            }
            
            for(let name in targetValues){
                let config = targetValues[name]
                if(config.model.type=='array'){
                    this.api_data.values[name] = []
                }else if(config.model.type=='object'){
                    this.api_data.values[name] = {}
                }else{
                    this.api_data.values[name] = null
                }
            }

            this.diagnose()

        },
        saveFactors: function(){
            let storage = window.localStorage;
            // let cache = storage.getItem('testAlgorithm') ? JSON.parse(storage.getItem('testAlgorithm')) : {}

            let cache = {api_data:this.api_data};
            
            storage.setItem('testAlgorithm',JSON.stringify(cache));
        },
        loadCache: function(){
            let storage = window.localStorage;
            let cache = storage.getItem('testAlgorithm') ? JSON.parse(storage.getItem('testAlgorithm')) : {}
            this.loadProfile(cache.api_data)
            this.diagnose()
        },
        copyAPI_DATA: function(){
            let text = document.getElementById('api_data_clipboard').textContent
            console.log(text)
            navigator.clipboard.writeText(text)
        },
        uploadAPI_DATA: function(){
            let data = JSON.parse(this.ui.form.raw_api_data)
            this.loadProfile(data)
        }
    },
    computed: {
        api: function(){
            return this.$store.getters.api[this.$store.getters.env]
        }
    },
    watch: {
        api: function(){
            this.getAlgorithmInfo()
        }
    }
    
}
</script>

<style>

</style>