<template>
  <div>
      <component v-bind:is="componentTarget" :algorithmInfo="algorithmInfo" v-model="api_data"/>      
  </div>

</template>

<script>
const init = require('./api_data.js')
//import kctrnt from './kidney_cancer/MCSPC.vue'
import kctrnt from './kidney_cancer/KidneyCancer.vue'
export default {
    created: function(){
        this.sync_model()
        this.ready = true
        if(!this.$options.components[this.componentTarget]){
            this.$router.push('/')
        }
    },
    mounted: function(){
    },
    props: {
        algorithmInfo: {
            type: Object,
            required: true
        }
    },
    components: {
        kctrnt
    },
    data: function(){
        return {
            ready: false,
            api_data: {}
        }
    },
    methods: {
        sync_model: function(){
            let self = this
            let promises = []
            promises.push(new init.createKeys(self))
            promises.push(new init.syncFactors(self))
            promises.push(new init.syncValues(self))
            Promise.all(promises).then(()=>{
                self.$forceUpdate()
            })
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        api: function(){
            return this.$store.getters.api[this.$store.getters.env]
        },
        componentTarget: function(){
            return this.$route.params.survey ? this.$route.params.survey : 'kctrnt'
        },
        v_model: function(){
            return this.$attrs.value
        }
    },
    watch: {
        api_data: {
            deep: true,
            handler: function(){
                this.$emit('input',this.api_data)
            }
        }
    }
}
</script>

<style>

</style>