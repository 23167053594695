<template>
<div>
    <table border="0">
        <tr>
            <td colspan='2' style="text-align:center;">
                <div class="cua-treatment-header" v-html="kt_content.treatments[kt_id].content.title[language]"/>
            </td>
        </tr>
        <tr class="treatment" v-if="kt_content.treatments[kt_id].content.start[language].length">
            <td>
                <img class="treatment-icon" :src="images.icons.start"/>
            </td>
            <td>
                <div class="cua-treatment-section">
                    <h3>
                        <Str index="treatment > section > start" data-test-treatment-section="start"/>
                    </h3>
                    <!-- <div v-html="kt_content.treatments[kt_id].content.start[language]" data-test-treatment-section-start/> -->
                    <sectionItem :items="kt_content.treatments[kt_id].content.start[language]" :kt_id="kt_id" :sectionKey="'start'"/>
                </div>
            </td>
        </tr>
        <tr class="treatment" v-if="kt_content.treatments[kt_id].content.monitoring[language].length">
            <td>
                <img class="treatment-icon" :src="images.icons.monitoring"/>
            </td>
            <td>
                <div class="cua-treatment-section">
                    <h3>
                        <Str index="treatment > section > monitoring" data-test-treatment-section="monitoring"/>
                    </h3>
                    <!-- <div v-html="kt_content.treatments[kt_id].content.monitoring[language]"/> -->
                    <sectionItem :items="kt_content.treatments[kt_id].content.monitoring[language]" :kt_id="kt_id" :sectionKey="'monitoring'"/>
                </div>
            </td>
        </tr>
        <tr class="treatment" v-if="kt_content.treatments[kt_id].content.progression[language].length">
            <td>
                <img class="treatment-icon" :src="images.icons.progressing"/>
            </td>
            <td>
                <div class="cua-treatment-section">
                    <h3>
                        <Str index="treatment > section > progression" data-test-treatment-section="progression"/>
                    </h3>
                    <!-- <div v-html="kt_content.treatments[kt_id].content.progression[language]"/> -->
                    <sectionItem :items="kt_content.treatments[kt_id].content.progression[language]" :kt_id="kt_id" :sectionKey="'progression'"/>
                </div>
            </td>
        </tr>
        <tr class="treatment" v-if="kt_content.treatments[kt_id].content.qol[language].length">
            <td>
                <img class="treatment-icon" :src="images.icons.qol"/>
            </td>
            <td>
                <div class="cua-treatment-section">
                    <h3>
                        <Str index="treatment > section > qol" data-test-treatment-section="qol"/>
                    </h3>
                    <!-- <div v-html="kt_content.treatments[kt_id].content.qol[language]"/> -->
                    <sectionItem :items="kt_content.treatments[kt_id].content.qol[language]" :kt_id="kt_id" :sectionKey="'qol'"/>
                </div>
            </td>
        </tr>





        <tr class="treatment" v-if="kt_content.treatments[kt_id].content.links.length">
            <td>
                <img class="treatment-icon" :src="images.icons.link"/>
            </td>
            <td>
                <div class="cua-treatment-section">
                    <h3>
                        <Str index="treatment > section > links" data-test-treatment-section="links"/>
                    </h3>
                    <ul>
                    <li v-for="(linkKey, linkIndex) in kt_content.treatments[kt_id].content.links" :key="linkIndex">
                        <a :href="kt_content.links[linkKey][language].link" target='_blank' :data-test-treatment-link="kt_id+'_'+linkIndex">
                            {{kt_content.links[linkKey][language].text}}
                        </a>
                    </li>
                    </ul>
                </div>
            </td>
        </tr>
        <tr class="treatment" v-if="kt_content.treatments[kt_id].content.references[language].length">
            <td>
                <img class="treatment-icon" :src="images.icons.references"/>
            </td>
            <td>
                <div class="cua-treatment-section">
                    <h3>
                        <Str index="treatment > section > references" data-test-treatment-section="references"/>
                    </h3>
                    <div class="reference" v-for="(reference, referenceIndex) in kt_content.treatments[kt_id].content.references[language]" v-html="reference" :key="'reference_'+referenceIndex" :data-test-treatment-reference="kt_id+'_'+referenceIndex"/>
                </div>
            </td>
        </tr>
    </table>
    <div v-html="style"/>
</div>
</template>

<script>
import icon_links from '@/components/Surveys/kidney_cancer/assets/icons/icon_links.png'
import icon_monitoring from '@/components/Surveys/kidney_cancer/assets/icons/icon_monitoring.png'
import icons_progressing from '@/components/Surveys/kidney_cancer/assets/icons/icon_progressing.png'
import icon_quality from '@/components/Surveys/kidney_cancer/assets/icons/icon_quality.png'
import icon_references from '@/components/Surveys/kidney_cancer/assets/icons/icon_references.png'
import icon_start from '@/components/Surveys/kidney_cancer/assets/icons/icon_start.png'
import sectionItem from './sectionItem.vue'

export default {
    props: {
        pdf_style: {
            type: Boolean,
            required: false,
            default: false
        },
        language: {
            type: String,
            required: false,
            default: 'en'
        },
        kt_content: {
            type: Object,
            required: true
        },
        kt_id: {
            type: Number,
            required: true
        }
    },
    components: {
        sectionItem
    },
    computed: {
        images: function(){
            return {
                icons: {
                    start: icon_start,
                    monitoring: icon_monitoring,
                    progressing: icons_progressing,
                    qol: icon_quality,
                    link: icon_links,
                    references: icon_references,
                }
            }
        },
        style: function(){
            let output = `
.treatment td{
    vertical-align: top;
    padding-bottom: 25px;
}

.treatment.row{
    margin-bottom: 25px;
}

img.treatment-icon{
    /* background-color: #E9212E !important; */
    /* color: white !important; */
    /* border: 1px solid red; */
    /* border-radius: 50%; */
    /* padding: 5px; */
    position: relative;
    left: -8px;
    top: -8px;
    width: 35px;
}

@media only screen and (max-width: 1300px) {
    .treatment-icon{
        top: 0px;
        width: 30px;
    }
}

.cua-treatment-header{
    color: var(--v-brandGreen-base);
    font-weight: bold;
    font-size: 16pt;
    margin: 25px 0px 25px 0px;
}

.cua-treatment-section h3{
    color: var(--v-brandGreen-base);
}

.cua-treatment-section ul {
  list-style: none;
}

.cua-treatment-section ul li:before{
  content: "";
  border-color: transparent var(--v-brandGreen-base);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1.2em;
  top: 1.2em;
  position: relative;
}

.reference{
    font-size: 9pt;
    padding: 8px;
}
.reference div{
    margin-bottom: 2px;
}
            `;

            if(this.pdf_style){
                output=`
                .treatment{
                    font-family: Roboto, sans-serif;
                    font-size: 9pt;
                }

.treatment td{
    vertical-align: top;
    padding-bottom: 0px;
}

img.treatment-icon{
    /* background-color: #E9212E !important; */
    /* color: white !important; */
    /* border: 1px solid red; */
    /* border-radius: 50%; */
    /* padding: 5px; */
    position: relative;
    left: -5px;
    top: -5px;
    width: 25px;
}

@media only screen and (max-width: 1300px) {
    .treatment-icon{
        top: 0px;
        width: 30px;
    }
}

.cua-treatment-header{
    margin: 10px 0px 10px 0px;
    color: var(--v-brandGreen-base);
    font-weight: bold;
    font-size: 16pt;
}

.cua-treatment-section h3{
    color: var(--v-brandGreen-base);
}

.cua-treatment-section ul {
    margin: 15px 0px 0px 15px;
  list-style: none;
    padding-left: 0px !important;
    position: relative;
    top: -15px;
}

.cua-treatment-section ul li:before{
  content: "";
  border-color: var(--v-brandGreen-base);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1.2em;
  top: 1em;
  position: relative;
}

.reference{
    font-size: 7pt;
    padding: 5px;
}
.reference div{
    margin-bottom: 2px;
}

.summary-alt-grey:nth-child(even){
    background-color: whitesmoke;
}

.pageBreak{
    page-break-after: always;
}
                `
            }
            return "<style type='text/css'>"+output+"</style>"
        }
    },
}
</script>

<style>

</style>