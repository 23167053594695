<template>
    
    <ul>
        <template v-for="(item, index) in items">
            <template v-if="typeof item=='string'">
                <li :key="index" v-html="item" :data-test-treatment-kt-id="kt_id" :data-test-treatment-section="sectionKey" :data-test-treatment-section-item="index"/>
            </template>

            <template v-else>
                <ul :key="index">
                    <li v-for="(subItem, subIndex) in item" :key="'sub_item_'+index+'_'+subIndex" v-html="subItem" :data-test-treatment-section="sectionKey" :data-test-treatment-kt-id="kt_id" :data-test-treatment-section-item="index" :data-test-treatment-section-subitem="subIndex"/>
                </ul>
            </template>
        </template>
    </ul>
    
</template>

<script>
export default {
    props: {
        sectionKey: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        kt_id: {
            type: Number,
            required: true
        }
    }
}
</script>

<style>

</style>